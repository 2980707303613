// __      __     _____  _____          ____  _      ______  _____    ____  _   _ _  __     __
// \ \    / /\   |  __ \|_   _|   /\   |  _ \| |    |  ____|/ ____|  / __ \| \ | | | \ \   / /
//  \ \  / /  \  | |__) | | |    /  \  | |_) | |    | |__  | (___   | |  | |  \| | |  \ \_/ /
//   \ \/ / /\ \ |  _  /  | |   / /\ \ |  _ <| |    |  __|  \___ \  | |  | | . ` | |   \   /
//    \  / ____ \| | \ \ _| |_ / ____ \| |_) | |____| |____ ____) | | |__| | |\  | |____| |
//     \/_/    \_\_|  \_\_____/_/    \_\____/|______|______|_____/   \____/|_| \_|______|_|

// Place additional branding files here from http://gitlab.imendo.at/Framework/Branding
// $primary-color: yellow;

// ANT Colors & Styles can be overwritten here. Just use the variable names from
// https://github.com/ant-design/ant-design/blob/master/components/style/themes/default.less
// and overwrite them here (please do not copy paste all of them :) ). Be sure to use $xyz, not @xyz!

$magenta: #a6214c;
$yellow: #FF7F11;
$blue: #508CA4;
$green-light: #329F5B; 

$black: #222;
$gray: #e3e3e3;

$background: $gray;
$magenta-hover: #e4c1cc;

$font-color: $black;

$logo-background: #fff;
$content-background: $background;

// --- OLD --- //
$main-accent-light: #3cadbf !default;
$main-accent-dark: #141630 !default;
$main-red-dark: #ff2d02;
$primary-color: #3cadbf;
$logo-background: #04040a !default;
  
$font-color-main: #565b5e;
$font-color-dark: #141630;

$menu-white: #f4f4ff;
$menu-gray: #b3b3b7;

$sidebar-font-color: #acacb6 !default;
$reporting-sidebar-color: #42424a;
$reporting-sidebar-color-hover: #5d5d69;
$sidebar-background: #fff;

$card-header: #808394;

$action-color: #3cadbf;
$action-color-light: #4dcde0;

$accent-color-1-dark: #c73012 !default;
$accent-color-1-light: #eb5a3e !default;
$accent-color-2-dark: #ccdb41 !default;
$accent-color-2-light: #d2e800 !default;
$accent-color-3-dark: #188394 !default;
$accent-color-3-light: #62bdcb !default;
$accent-color-4-dark: #0b0e2d !default;
$accent-color-4-light: #2f2f36 !default;
$accent-color-5-dark: #9e9ea8 !default;
$header-active: $main-accent-light;
