@import '_variables';
@import '_mixins';

@import 'imendo-lib/library';
@import '~react-quill/dist/quill.snow.css';
@import 'ant-override';
@import 'table';
@import 'sidebar';

@import 'headbar';

@font-face {
  font-family: 'Raleway Semibold';
  font-style: normal;
  font-weight: 400;
  src: url('./fonts/Raleway-SemiBold.ttf') format('truetype');
}

@font-face {
  font-family: 'Raleway Medium';
  font-style: normal;
  font-weight: 600;
  src: url('./fonts/Raleway-Medium.ttf') format('truetype');
}

@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  src: url('./fonts/OpenSans-Regular.ttf') format('truetype');
}


body {
  font-family: 'Raleway Medium', "Segoe UI", sans-serif;
}

h1 {
  font-size: 2rem;
  text-align: center;
}

h1, h2, h3, h4 {
    font-family: 'Raleway Semibold', "Segoe UI", sans-serif;
}

.highlighted-text {
  color: lighten($magenta, 5);
}

.register-btn {
  margin-top: 20px;
}

.back-btn {
  display: inline-block;
  padding: 10px 0 20px 0;
}

.ant-spin {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.ant-card {
  border: none;
}

.report-row {
  height: calc(100vh - 64px - 20px - 50px - 42px);
}

.report-name {
  display: inline-block;
}

.report-container {
  height: 100%;
  display: flex;

  iframe {
    background: #fff;
    padding: 10px 10px 0 10px;
    border: none;
    border-radius: $border-radius;
  }
}

.rotated-iframe {
  height: 100vh;

  iframe {
    height: 90vw !important;
    width: calc(100vh - #{$header-height} * 2)  !important;
    transform: rotate(90deg) translateY(30%) translateX(calc(#{$header-height} * 2));
  }
}

.powerbi-options {
  float: right;
  padding-bottom: 10px;

  .option-button {
    margin-left: 15px;
  }
}

.modal-input-row {
  margin-bottom: 25px;

  .label {
    margin-bottom: 5px;
  }
}

.overlay {
  display: none;
}

//imported last so it has highest priority
@import 'mobile';

.imd-logo-cls-1 {
  fill: #3db1dc;
}
.imd-logo-cls-2 {
  fill: #2b9eca;
}
.imd-logo-cls-3 {
  fill: #0f8bbe;
}
.imd-logo-cls-4 {
  fill: #0a7dae;
}
.imd-logo-cls-5 {
  fill: #63c2e8;
}
.imd-logo-cls-6 {
  fill: #f33541;
}
.imd-logo-cls-7 {
  fill: #fd5863;
}
.imd-logo-cls-8 {
  fill: #fd7b86;
}
.imd-logo-cls-9 {
  fill: #8fdcf7;
}
.imd-logo-cls-10 {
  fill: #a8e5fd;
}
.imd-logo-cls-11 {
  fill: #caeffd;
}
.imd-logo-cls-12 {
  fill: #92000f;
}
.imd-logo-cls-13 {
  fill: #f33d49;
}
.imd-logo-cls-14 {
  fill: #c11a2a;
}
.imd-logo-cls-15 {
  fill: #dc2632;
}
body,
#root {
  height: 100vh;
}
.imd-parent {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  animation: IMDfadeIn 1s linear;
}
.imd-svg {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}
.imd-text-pb {
  margin-top: 40px;
  margin-left: 10px;
  font-size: 20pt;
  color: #acacac;
}
.imd-text-imendo {
  color: #45bde6;
  font-size: 100pt;
  letter-spacing: -0.08em;
  font-kerning: auto;
}
#cube1 > .imd-logo-shape {
  top: -51px;
  left: -620px;
  transform: rotate3d(13, 110, 130, 90deg);
  animation-delay: 0s !important;
}
#cube2 > .imd-logo-shape {
  top: -105px;
  left: -541px;
  transform: rotate3d(13, 110, 130, 90deg);
  animation-delay: 0.5s !important;
}
#cube3 > .imd-logo-shape {
  top: -77px;
  left: -573px;
  transform: rotate3d(13, 110, 130, 90deg);
  animation-delay: 1s !important;
}
.imd-logo-container {
  width: 100%;
  perspective: 2000;
}
.imd-logo-shape {
  transform-style: preserve-3d;
  position: relative;
  margin: 0 auto;
  width: 48px;
  height: 48px;
  animation: spin 6s infinite linear;
}
.imd-logo-shape > div {
  position: absolute;
  width: 48px;
  height: 48px;
}
@keyframes spin {
  0% {
    transform: rotateY(50deg) rotateX(-20deg) rotateZ(-112deg);
  }
  12.5% {
    transform: rotateY(50deg) rotateX(-20deg) rotateZ(-22deg);
  }
  25% {
    transform: rotateY(50deg) rotateX(-20deg) rotateZ(-22deg);
  }
  37.5% {
    transform: rotateY(50deg) rotateX(-20deg) rotateZ(68deg);
  }
  50% {
    transform: rotateY(50deg) rotateX(-20deg) rotateZ(68deg);
  }
  62.5% {
    transform: rotateY(50deg) rotateX(-20deg) rotateZ(158deg);
  }
  75% {
    transform: rotateY(50deg) rotateX(-20deg) rotateZ(158deg);
  }
  87.5% {
    transform: rotateY(50deg) rotateX(-20deg) rotateZ(248deg);
  }
  100% {
    transform: rotateY(50deg) rotateX(-20deg) rotateZ(248deg);
  }
}
@keyframes IMDfadeIn {
  0% {
    display: none;
    opacity: 0;
  }
  50% {
    display: none;
    opacity: 0;
  }
  70% {
    display: none;
    opacity: 1;
  }
}
.imd-logo-one {
  background-color: #92000f;
  transform: rotateX(90deg) translateZ(24px);
}
.imd-logo-two {
  background-color: #fd7b86;
  transform: translateZ(24px);
}
.imd-logo-three {
  background-color: #f33d49;
  transform: rotateY(90deg) translateZ(24px);
}
.imd-logo-four {
  background-color: #fd7b86;
  transform: rotateY(180deg) translateZ(24px);
}
.imd-logo-five {
  background-color: #f33d49;
  transform: rotateY(-90deg) translateZ(24px);
}
.imd-logo-six {
  background-color: #92000f;
  transform: rotateX(-90deg) translateZ(24px);
}
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .imd-logo-shape {
    animation: 0s !important;
    transform: none !important;
  }
  .imd-cube-animated {
    display: none;
  }
  .imd-svg polyline {
    display: none;
  }
  .imd-svg svg {
    background-image: url('<%= htmlWebpackPlugin.options.publicPath %>square-animated.gif');
    background-repeat: no-repeat;
    width: 265px;
    height: 298px;
  }
}

.button-wide {
  width: 200px;
}
