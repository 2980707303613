.header {
  background: #fff;
  display: flex;
  justify-content: space-between;
  font-size: 0.8rem;

  @include box-shadow-dark;
  z-index: 1;
}


.icon-logo-mobile { 
  display: none;
}

.toggle-menu-wrapper {
  @include center-vertically();
  height: 100%;

  #toggle-menu-sidebar-btn {
    @include icon-button($black);
    padding: 0 20px;
    margin: 0 calc(#{$header-padding} - 20px);
    font-size: 1.2rem;
  }

  .toggle-caret {
    color: $magenta;
    font-size: 13px;
    margin: 0px 4px 3px 4px;
  }
}

.logoutMenu {
  display: inline-block;
  text-align: right;
//  padding: 0 $header-padding;
  width: $logout-menu-width;
  text-align: center;
  background: $magenta;

  a {
    color: #fff;
  }

  svg {
    margin-left: 5px;
  }
}

.logoutMenu-mobile {
  display: none;
}

.header-dropdown-menu {
  width: $logout-menu-width;
  top: $header-height !important;

  .dropdown-menu {
    border-radius: 0;
    padding: 0;
    // text-align: center;

    .admin-link, .logout {
      &:hover {
        background: #efefef;
      } 
      
      padding: 12px 20px;

      svg {
        margin-right: 10px;
        color: $blue;
      }
    }

    .logout {
      color: $magenta;
      border-top: 1px solid $gray;
      svg, a {
        color: $magenta;
      }
    }
  }
}
