@import 'imendo-lib/_mixins';
@import '~include-media/dist/_include-media';

@mixin ie {
  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    @content;
  }
}

@mixin center {
  display: flex;
  justify-content: center;
  align-items: center;
}

@mixin center-vertically {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

@mixin text-overflow {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

@mixin icon-button($color) {
  padding: 0px;
  border: 0;
  box-shadow: none;
  animation-duration: 0s !important;
  background: transparent;
  outline: none;
  color: $color;

  &:focus,
  &:hover,
  &:active {
    color: lighten($color, 20) !important;
    background: transparent;
    outline: none !important;
    box-shadow: none;
    border: none;
  }

  &:after {
    animation: 0s !important;
  }
}

.float-right {
  float: right;
}

.display-none {
  display: none !important;
}


@mixin box-shadow-dark {
  -webkit-box-shadow: 3px 3px 15px rgba(#000, 0.3);
  -moz-box-shadow: 3px 3px 15px rgba(#000, 0.3);
  box-shadow: 3px 3px 15px rgba(#000, 0.3);
}

@mixin box-shadow {
  -webkit-box-shadow: 3px 3px 15px rgba(#000, 0.15);
  -moz-box-shadow: 3px 3px 15px rgba(#000, 0.15);
  box-shadow: 3px 3px 15px rgba(#000, 0.15);
}