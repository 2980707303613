//customer-specific colors; imendo-theme-dark:
$main-accent-light: #c92128 !default; //accent color for links, icons etc.
$main-accent-dark: #a31b20 !default;

$logo-background: black !default;

$sidebar-font-color: rgba(255,255,255,0.65) !default;
$sidebar-icon-color: $sidebar-font-color !default; 
$sidebar-background: #182123 !default; //background of navigation/sidebar
$sidebar-emphasis: #091211 !default; //background of selected e.g. menu item
$sidebar-emphasis-font-color: white !default;
$sider-header-background: #091211 !default; //accent color or sidebar-selected color or something

$accent-color-1-dark: #a31b20 !default; 
$accent-color-1-light: #b51e23 !default;
$accent-color-2-dark: #d38547 !default; 
$accent-color-2-light: #de9421 !default;
$accent-color-3-dark: #25921f !default;
$accent-color-3-light: #57b352 !default;
$accent-color-4-dark: #489890 !default;
$accent-color-4-light: #57b8ad !default;

$primary-controls: #469592 !default; //buttons, actions, radiobuttons
$primary-controls-dark: #247370 !default;
$primary-controls-light: #57a6a3 !default;
