.ant-table-margin {
  margin-top: 50px;
}

.table-action-btn {
  @include icon-button($blue);
}

.table-action-btn-del {
  @include icon-button($magenta);
}

.table-action-btn-edit {
  @include icon-button($blue);
}

.table-action-btn-folder {
  @include icon-button($magenta);
}

.sorters-btn-wrapper {
  .table-action-btn-sort:last-child {
    margin-top: -30px;
  }
}

.table-action-btn-sort {
  @include icon-button(#404040);
  display: block;
  margin-left: auto;
  margin-right: auto;
  font-size: 20px;
}

.table-icon-seperator {
  margin-left: 8px;
  margin-right: 8px;
}

.ant-table-body {
  overflow-x: auto !important;
}