@import 'styles/_variables';

.logo {
  text-align: center;

  img {
    display: inline-block;
    height: 60px;
    margin: 30px 20px;
  }
  &:hover {
    cursor: pointer;
  }
}

.span-report-menu {
  padding-left: 10px;
  width: calc(#{$sidebar-width} - 10px - 19px);

  @include text-overflow;
}

.ant-layout-sider {
  box-shadow: none;
  z-index: 2;
}

.ant-layout-sider .ant-layout-sider-children .ant-menu .ant-menu-item {
  color: $font-color;
  margin: 0;
  padding: 6px;
  height: 50px;
  &:hover {
    background: $magenta-hover;
    //color: #fff;
  }
}

.ant-layout-sider .ant-layout-sider-children .ant-menu .ant-menu-item-selected {
  background: $magenta;
  color: #fff;

  &:hover {
    color: #fff;
    background: lighten($magenta, 5);
  }
}
