//Add new component scss files here
@import 'cards';
@import 'buttons';
@import 'inputs';
@import 'modals';

//GLOBAL styles only (no demo pages, documentation, etc), this gets imported to other projects!
//DO NOT define component styles here!
@font-face {
    font-family: MetropolisLight;
    src: url(Metropolis-Light.otf);
};

.ant-layout-content {
    background: $content-background;
}

.ant-layout {
    min-height: 100vh;
}

.ant-layout-sider {
    background: $sidebar-background;

    .ant-layout-sider-children {
        @include box-shadow;
        
        height:100%;
        .ant-menu {
            background: $sidebar-background;
            color: $sidebar-font-color;

            .ant-menu-item {
                width: 100%;

                &:after {
                    border-right: none;        
                }
            }
        
            .ant-menu-item-selected {
            background: $sidebar-emphasis;
            color: $sidebar-emphasis-font-color;

                &:before {                
                    border-right: 6px solid $main-accent-light;
                    content: "";
                    position: absolute;
                    left: 0;
                    top: 0;
                    bottom: 0;
                }
            }
        
        }
        
        .ant-menu-inline {
            border: 0px;
        }
    }

    .ant-layout-sider-trigger, .ant-layout-header {
        background: $sidebar-background;
        color: $sidebar-font-color;
        box-shadow: 1px 1px 6px #bababa;
    }
}

.ant-layout-content {
    padding: $content-padding $content-padding $content-padding / 2 $content-padding;
}

.logo {
    background: $logo-background;
    width: 100%;
    text-align: center;


    img {    
        display: inline-block;
        height: $logo-height;
        margin: (64px - $logo-height) / 2 0;
    }
}

.code {
    font-family: 'Consolas';
}