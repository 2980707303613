@media screen and (max-width: $sm) {
  .ant-layout-content {
    padding: 10px 0;
  }

  .ant-layout-sider {
    position: absolute;
    z-index: 100;
    height: 100vh;
    overflow-y: auto;
  }

  .overlay {
    display: block;
    position: absolute;
    z-index: 50;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100vw;
    background: rgba(#000, 0.4);
  }

  .header {
    justify-content: space-between;
  }

  .icon-logo-mobile {
    display: block;
    height: $header-height;
    background: #fff;
    padding: 0 20px;

    img {
      height: calc(#{$header-height} - 30px);
    }
  }

  .toggle-menu-wrapper {
    transform: translateX(-50vw) translateX(100px);
    &.mobile {
      position: absolute;
      height: $header-height;
      z-index: 100;
      top: 0;
      left: $sidebar-width;
      background: #fff;
      transform: translateX(-0);

      #toggle-menu-sidebar-btn {
        margin: 0px;
        padding: 0 20px 0 10px;
      }
    }
  }

  .logoutMenu {
    display: none;
  }

  .logoutMenu-mobile {
    right: 0;
    display: block;
    font-size: 1.2rem;
    background: $magenta;
    color: #fff;

    &:active,
    &:focus,
    &:hover {
      background: lighten($magenta, 5);
    }
    a {
      color: #fff;
      height: $header-height;
      padding: calc(#{$header-height} - 1.2rem) 25px;
     
    }
  }

  .report-row {
    height: 150vh;
  }

  .option-button {
    display: none;
  }
  
  h2 {
    padding: 10px;
  }

  .ant-btn {
    margin-right: 10px;
  }


  .ant-card-body {
    padding: 20px 10px;

    .ant-btn {
      margin-right: 0;
    }

    td {
      padding: 8px 16px !important;
    }
  }
}


.ant-card {
  @include box-shadow;
}
