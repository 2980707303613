@import '_branding';

//global colors
$content-background: #e0e0e0 !default; //background of main page

$card-header: #f2f2f2 !default; //light gray hue as header; gray hue ant: #fafafa
$card-selected: #f3f3f3 !default; //ant card hover: #e6f7ff
$card-icons: #cccccc !default; //ant: #898989; disabled: #e2e2e2

$import-export-field-1: #e6e6e6 !default;
$import-export-field-2: #d1d1d1 !default;


$font-color-main: #666666 !default; //main content,cards; ant: #595959
$font-color-tabs: #999999 !default; //tabs, icons; "card-header-font"-ant: #5c6b77;
$font-color-current-breadcrumb: #808080 !default; //ant: #595959; breadcrumb-path: #8c8c8c

$font-color-header-main: #333333 !default;
$font-color-header: #1a1a1a !default;

$table-header-row: #f7f7f7 !default;
$table-selected-row: #f6f8f9 !default;
$table-font-color: #4f4f4f !default;

$logo-height: 40px !default;

//other constants
$content-padding: 50px !default;

//ANT MEDIA MIN BREAKPOINTS
$sm: 576px !default;
$md: 768px !default;
$lg: 992px !default;
$xl: 1200px !default;
$xxl: 1600px !default;

// For Media Mixin (with some aliases, e.g. sm=phone=576px), MUST be named $breakpoints
$breakpoints: (
    sm: $sm,
    md: $md,
    lg: $lg,
    xl: $xl,
    xxl: $xxl,
    
    phone: $sm,
    tablet: $md,
    tabletv: $md,
    tableth: $lg,
    desktop: $xl,
    large: $xxl,
) !default;