//Wrap all component styles in the respective class
.imd-demobutton {
 
    border-radius: 2px;
    border: 1px solid $primary-controls;
    color: #ffffff;
    background: $primary-controls;
    padding: 0px 40px 0px 40px;
    
    &:focus {
    border-radius: 2px;
    border: 1px solid $primary-controls;
    color: #ffffff;
    background: $primary-controls;
    padding: 0px 40px 0px 40px;
    }

    &:hover {
        color: #ffffff;
       border: 1px solid $primary-controls-light;
       border-bottom: 1px solid $primary-controls;
       background: $primary-controls-light;
    }

    
    &:active,  .ant-btn:active, .ant-btn.active {
        border: 1px solid $primary-controls-dark;
        border-bottom: 1px solid $primary-controls;
        background: $primary-controls-dark;
    }

}

.imd-radiobutton {

    .ant-radio-wrapper:hover .ant-radio .ant-radio-inner, .ant-radio:hover .ant-radio-inner, .ant-radio-focused .ant-radio-inner {
        border: 1px solid $primary-controls !important;
    }

    .ant-radio-inner:after {
        background: $primary-controls !important;
    }

    .ant-radio-checked .ant-radio-inner {
        border: 1px solid $primary-controls !important;
    }

     &::selection {
        background: $primary-controls !important;
     }

}

