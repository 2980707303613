// PROMPT
.ant-modal.prompt {
    .ant-modal-header {
        border-bottom: none;
        padding: 24px 32px 16px 32px;
    }

    .ant-modal-body {
        padding: 0 32px;
    }

    .ant-form-item {
        margin-bottom: 0;

        &:only-child {
            margin-top: 8px;
        }
    }    

    .ant-modal-footer {
        border-top: none;
        padding: 24px 32px;
    }
}