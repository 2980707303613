.ant-btn {
  border-radius: 0;
  color: $magenta;
  padding: 4px 25px 0 25px;
  overflow: hidden;

  border: 1px solid $magenta;

  box-shadow: inset 0 0 20px rgba($magenta, 0);
  outline-color: rgba($magenta, 0.5);
  outline-offset: 0px;
  text-shadow: none;
  transition: all 1250ms cubic-bezier(0.19, 1, 0.22, 1);

  &:hover {
    color: #fff;
    background: $magenta;
    border: 1px solid;
    box-shadow: inset 0 0 20px rgba($magenta, 0.5), 0 0 20px ($magenta, 0.2);
    outline: 1px solid;
    outline-color: rgba($magenta, 0);
    outline-offset: 15px;
  }

  &.yellow {
    color: $yellow;
    border: 1px solid $yellow;
    box-shadow: inset 0 0 20px rgba($yellow, 0);
    outline-color: rgba($yellow, 0.5);

    &:hover,
    &:focus,
    &:active {
      color: #fff;
      background: $yellow;
      box-shadow: inset 0 0 20px rgba($yellow, 0.5), 0 0 20px ($yellow, 0.2);
      outline-color: rgba($yellow, 0);
    }
  }

  &.blue {
    color: $blue;
    border: 1px solid $blue;
    box-shadow: inset 0 0 20px rgba($blue, 0);
    outline-color: rgba($blue, 0.5);

    &:hover,
    &:focus,
    &:active {
      color: #fff;
      background: $blue;
      box-shadow: inset 0 0 20px rgba($blue, 0.5), 0 0 20px ($blue, 0.2);
      outline-color: rgba($blue, 0);
    }
  }

  &.white {
    background: #fff;
    color: $font-color;
    border: 1px solid $gray;
    &:hover,
    &:focus,
    &:active {
      color: darken($font-color, 20);
      border-color: darken($gray, 20);

      box-shadow: none;
      outline: 0;
    }
  }

  &.ant-btn-primary {
    background: $magenta;
    color: #fff;
    border: 1px solid $gray;

    &:hover {
      background: lighten($magenta, 10);
      box-shadow: none;
      outline: 0;
    }
  }
}

.ant-form-explain, .ant-form-extra {
  color: $magenta;
}

.ant-layout-sider
  .ant-layout-sider-children
  .ant-menu
  .ant-menu-item-selected:before {
  border: none;
}

.ant-layout-sider .ant-layout-sider-children .ant-menu .ant-menu-item {
  font-size: 14px;
}

.ant-layout-content {
  padding: 50px 30px 20px 30px;
}

.ant-layout-header {
  padding: 0 !important;
}

.ant-spin-nested-loading {
  height: 100%;
}

.ant-spin-container {
  height: 100%;
}

.ant-menu-submenu {
  color: $font-color;
}
