//Wrap all component styles in the respective class
.imd-democard {
    box-shadow: 1px 1px 3px 1px $card-icons;
    border-radius: 2px;
 
    div {
        padding: 20px;
    }
}

.imd-democard-title {
  
        background-color: $card-header;
        padding: 15px 20px 5px 20px;
        border-top-left-radius: 1px;
        border-top-right-radius: 1px;
        margin: -20px -20px 20px -20px;
    
        h1 {
            color: $font-color-main;
            font-size: 12pt;
        }
}